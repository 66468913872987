<template>
  <div>
    <ul class="list-group list-group-flush" v-loading="loading.histories">
      <li class="list-group-item" v-if="histories.length == 0">No data</li>
      <li class="list-group-item" v-for="item in histories" :key="item.id">
        <strong :class="item.status_color" style="font-size: 13px">{{ item.invoice_number }}</strong>
        &nbsp;<a @click="copyLink(item.paylink)" class="cursor-pointer" v-if="item.paylink"><font-awesome :icon="['fas', 'share']"/></a>
        <br/>
        <small style="font-size: 11px">{{ item.created_at_str }}</small>
      </li>
    </ul>
  </div>
</template>
<script>
import moment from 'moment';
import trxApi from '@/api/transactions';

export default {
  name: 'ConvoTransactionList',
  data() {
    return {
      loading: {
        histories: true,
      },
      histories: [],
    };
  },
  props: {
    conversationId: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  methods: {
    async loadTransactions() {
      this.loading.histories = true;
      const response = await trxApi.listTransactions({
        workspace_id: this.activeWorkspace._id,
        query: {
          search_keyword: this.conversationId,
          search_by: 'conversation_id',
        },
      });
      this.loading.histories = false;
      const status_color_list = {
        waiting_payment: 'text-warning',
        expired: 'text-danger',
      };
      this.histories = response.data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        console.log(v.status, status_color_list[v.status]);
        v.status_color = status_color_list[v.status] || 'text-success';
        return v;
      });
    },
    copyLink(url) {
      navigator.clipboard.writeText(url).then(() => {
        this.$message({
          message: 'Copied',
          type: 'success',
        });
      }, (err) => {
        console.log(err);
      });
    },
  },
  watch: {
    expanded() {
      if (this.expanded) this.loadTransactions();
    },
    conversationId() {
      this.histories = [];
    },
  },
};
</script>
