<template>
  <b-modal v-model="showModal" title="Product" hide-footer size="xl" @hide="onClose">
    <div class="table-responsive mt-2">
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="15%">Thumbnail</th>
            <th width="20%">Name</th>
            <th width="25%">Description</th>
            <th>Price</th>
            <th width="5%">#</th>
          </tr>
        </thead>
        <tbody v-loading="loading.productList">
          <tr @click="appendProduct(item)" v-for="(item, i) in productList" :key="i">
            <td>
              <el-image
                style="width: 120px; height: 120px"
                :src="item.thumbnail"
                fit="cover"></el-image>
            </td>
            <td>{{ item.name }}</td>
            <td>
              {{ truncate(item.description, { length: 30 }) }}
            </td>
            <td>Rp.{{ item.price.toLocaleString() }}</td>
            <td>
              <button @click="appendProduct(item)" class="btn btn-outline-primary btn-sm mr-2">
                Use
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <el-empty :image="emptyImage" v-if="totalRow == 0" description="No data"></el-empty>
      <b-pagination v-if="totalRow > 10" v-model="currentPage" :total-rows="totalRow" :per-page="10"/>
    </div>
    <!-- <el-col :span="4" v-for="(item) in productList" :key="item.id" class="ml-2 mb-2">
      <el-card :body-style="{ padding: '0px' }">
        <img align="center" style="width: 150px; height: 150px" :src="item.thumbnail" class="image">
        <div style="padding: 14px;">
          <span>{{ truncate(item.name, { length: 20 }) }}</span>
          <div class="bottom clearfix">
          </div>
        </div>
      </el-card>
    </el-col> -->
    <div class="d-flex flex-row-reverse">
      <b-button @click="onClose" variant="secondary">{{ $t('general.close') }}</b-button>
    </div>
  </b-modal>
</template>
<script>
import { truncate } from 'lodash';
import productApi from '@/api/commerceProducts';
import emptyImage from '@/assets/images/undraw/empty.svg';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ConvoProductList',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      truncate,
      emptyImage,
      loading: {
        productList: false,
      },
      productList: [],
      totalRow: 0,
    };
  },
  methods: {
    appendProduct(item) {
      this.$emit('appendProduct', item);
    },
    async loadProductList() {
      this.loading.productList = true;
      const response = await productApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page,
        // search_keyword: this.search_keyword,
      });
      this.loading.productList = false;
      await popupErrorMessages(response);
      // console.log(response.data);
      this.totalRow = response.data.count;
      this.productList = response.data.rows.map((v) => {
        v.thumbnail = this.placeholderImage;
        v.thumbnail_type = 'image';
        if (v.product_attachments[0]) {
          const ath = v.product_attachments[0];
          v.thumbnail = ath.full_url;
          v.thumbnail_type = ath.mime_type.split('/')[0];
        }
        return v;
      });
    },
    onClose() {
      this.$emit('onClose');
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.loadProductList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.loadProductList();
      }
    },
  },
};
</script>
