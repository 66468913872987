<template>
  <b-modal v-model="showModal" title="Edit Bot Messages" hide-footer size="xl" @hide="onClose">
    <div class="table-responsive mt-2" v-loading="loading.bot_messages">
     <!-- edit text message bot here -->
      <b-form-group
        v-for="(v, index) in messages.filter((v) => v.topic === 'payment')" :key="index"
        :id="`input-messages-${index}`"
        :label="`Thread ${ upperCaseFirst(form_bot_messages[index].topic) }`"
        :label-for="`input-messages-${index}`"
      >
        <b-form-group
          :description="v.topic === 'payment' ? payment_desc : ''"
        >
          <b-form-textarea
            v-model="form_bot_messages[index].text"
            placeholder="Enter messages bot..."
            rows="3"
            max-rows="10"
          ></b-form-textarea>
        </b-form-group>
        <div class="row mt-3" v-if="v.topic === 'payment'">
          <div class="col-md-12">
            <el-alert :closable="false" class="mb-2" title="💡 Product Details" type="info">
              <ul>
                <li v-for="(item, idx) in cart_list" :key="idx">
                  <b>{{ item.name }}</b> Rp. {{ item.price.toLocaleString('id') }} x {{ item.qty }} = Rp. {{ item.total_amount.toLocaleString('id') }}
                </li>
              </ul>
            </el-alert>
          </div>
          <div class="col-md-12">
            <el-alert :closable="false" class="mb-3" title="💡 Payment Details" type="info">
              <p class="pl-4">Subtotal : <b>Rp. {{ total_amount.toLocaleString('id') }}</b></p>
            </el-alert>
          </div>
        </div>
      </b-form-group>

      <div class="d-flex flex-row-reverse">
        <b-button @click="onSubmit" variant="primary" class="ml-2">Submit</b-button>
        <b-button @click="onClose" variant="secondary">{{ $t('general.close') }}</b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { truncate, upperFirst } from 'lodash';
import commerceSettingApi from '@/api/commerceSetting';
import emptyImage from '@/assets/images/undraw/empty.svg';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ConvoEditBotTransaction',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    form_bot_messages: {
      type: Array,
      default() {
        return [];
      },
    },
    cart_list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showModal: false,
      truncate,
      emptyImage,
      loading: {
        bot_messages: false,
      },
      messages: [],
      details: {
        product: '',
        payment: '',
      },
      total_amount: 0,
      payment_desc: '',
      totalRow: 0,
    };
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    async loadBotMessages() {
      this.loading.bot_messages = true;
      const response = await commerceSettingApi.get_messages({ id: this.activeWorkspace._id });
      this.loading.bot_messages = false;
      await popupErrorMessages(response);
      if (response.data.messages_content) {
        this.messages = response.data.messages_content;
      } else {
        this.messages = [
          {
            topic: 'payment',
            text: 'Hai {{name}},\n\nPemesananmu sudah kami buat. Lakukan pembayaran untuk detail pemesanan dengan Order ID #{{order_no}}:\n{{product}}\n{{payment}}\n\nSegera selesaikan transaksimu, agar pemesananmu bisa diproses!',
          },
          {
            topic: 'invoice',
            text: 'Pembayaran untuk Order ID #{{order_no}} sudah terkonfirmasi. Berikut detail pemesanan kamu:\nNo Invoice : #{{invoice_no}}\n{{product}}\n{{payment}}\n\nTerimakasih sudah melakukan pemesanan.',
          },
        ];
      }
      this.payment_desc = '{{product}} and {{payment}} to be replace with below data';
      this.cart_list.forEach((v) => {
        this.total_amount += v.total_amount;
      });
      this.messages.forEach((v) => {
        this.form_bot_messages.push({
          topic: v.topic,
          text: v.text,
        });
      });
    },
    // async updateBotMessages() {
    //   this.loading.bot_messages();
    //   const response = await commerceSettingApi.update_messages({ id: this.activeWorkspace._id, data: { content: this.messages } });
    //   this.hideLoader();
    //   this.loading.update_bot = false;
    //   await popupErrorMessages(response);
    //   this.$message({
    //     type: 'success',
    //     message: this.$t('settings.success.edit'),
    //   });
    //   this.loadData();
    // },
    onSubmit() {
      this.loading.bot_messages = true;
      this.$emit('onSubmit');
    },
    onClose() {
      this.$emit('onClose');
    },
    hideLoader() {
      this.loading.bot_messages = false;
    },
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.loadBotMessages();
      }
    },
  },
};
</script>
