<template>
  <b-modal v-model="showModal" :title="'Media Detail'" hide-footer size="lg" @hide="onClose">
    <v-zoomer
      v-if="type === 'image'"
      style="width: 100%; height: 540px;"
      ref="zoomer"
      :aspect-ratio="imageAspectRatio"
      :max-scale="10"
      :zoomed.sync="zoomed"
    >
      <!-- :zooming-elastic="false" -->
      <img
        :src="url"
        style="object-fit: contain; width: 100%; height: 100%;"
        @load="onImageLoad"
      />
    </v-zoomer>
    <video v-else-if="type === 'video'" :src="url" class="w-100"/>
    <div class="d-flex flex-row-reverse mt-3">
      <!-- <el-button @click="sendNotification" :disabled="!isValidStep2" size="small" type="primary" class="ml-2">Send</el-button> -->
      <el-button @click="onClose" size="small">Close</el-button>
      <template v-if="type === 'image'">
        <el-button type="info" class="mr-2" size="small" @click="downloadImage()">Download</el-button>
        <el-button type="primary" class="" size="small" @click="$refs.zoomer.zoomIn()">+</el-button>
        <el-button type="primary" class="" size="small" @click="$refs.zoomer.zoomOut()">-</el-button>
      </template>
    </div>
  </b-modal>
</template>

<script>
import VueZoomer from 'vue-zoomer';

export default {
  name: 'ConvoReachTicket',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'image',
    },
    url: {
      type: String,
      default: '',
    },
  },
  components: {
    VZoomer: VueZoomer.Zoomer,
  },
  data() {
    return {
      showModal: false,
      // page: 1,
      // moment,
      loaderStack: 0,
      loader: null,
      zoomed: false,
      imageAspectRatio: 1,
    };
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    onImageLoad(e) {
      const img = e.target;
      this.imageAspectRatio = img.naturalWidth / img.naturalHeight;
    },
    async downloadImage() {
      const split_url = this.url.split('/');
      const file_name = split_url[split_url.length - 1];
      const blob = await (await fetch(`/proxy?img=${this.url}`)).blob();
      const url = URL.createObjectURL(blob);
      Object.assign(document.createElement('a'), { href: url, download: file_name }).click();
      URL.revokeObjectURL(url);
    },
  },
  watch: {
    async show() {
      this.showModal = this.show;
      // if (this.show) {
      //   this.showLoader();
      //   this.resetAll();
      //   await this.getTemplate();
      //   this.hideLoader();
      // }
    },
  },
};
</script>

<style scoped>
</style>
