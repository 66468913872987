<template>
  <b-modal v-model="showModal" :no-close-on-esc="loading.submit" :no-close-on-backdrop="loading.submit" title="Create Transaction" hide-footer size="xl" @hide="onClose">
    <div class="table-responsive mt-2">
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="15%">Product</th>
            <th width="20%">Description</th>
            <th>Quantity</th>
            <th>Price</th>
            <th width="20%">Total Price</th>
            <th width="5%">#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in cart" :key="i">
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ truncate(item.description, { length: 20 }) }}
            </td>
            <td>
              <b-input @input="handleAmount(i)" type="text" size="sm" v-model="item.qty" placeholder="Input sell quantity"/>
              <small v-if="item.qty < 1" class="text-danger">Quantity must be greater than 0</small>
            </td>
            <td>
              <b-input @input="handleAmount(i)" type="text" size="sm" v-model="item.price" placeholder="Input sell price"/>
              <small v-if="item.price < 1" class="text-danger">Price must be greater than 0</small>
            </td>
            <td>
              Rp. {{ item.total_amount ? item.total_amount.toLocaleString() : 0 }}
            </td>
            <td>
              <el-button @click="cart.splice(i, 1)" size="mini" type="danger" icon="el-icon-delete" circle></el-button>
            </td>
          </tr>
          <tr @click="showModalProduct = true;">
            <td colspan="6">
              <el-button @click="showModalProduct = true" type="primary" size="mini"><font-awesome icon="plus"/> Select product</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex flex-row-reverse">
      <!-- <el-button size="small" :loading="loading.submit" @click="onSubmit" type="primary" class="ml-2">{{ $t('general.submit') }}</el-button> -->
      <el-button size="small" v-if="cart.length > 0" :loading="loading.submit" @click="() => { showModalEditBot = true; }" type="primary" class="ml-2">{{ $t('general.submit') }}</el-button>
      <el-button size="sm" :disabled="loading.submit" @click="onClose" variant="secondary">{{ $t('general.close') }}</el-button>
    </div>
    <product-list
      :show="showModalProduct"
      @onClose="showModalProduct = false"
      @appendProduct="appendProduct"
    />
    <edit-bot
      ref="editBot"
      :show="showModalEditBot"
      :form_bot_messages="form_bot_messages"
      :cart_list="cart"
      @onClose="showModalEditBot = false"
      @onSubmit="onSubmit"
    />
  </b-modal>
</template>
<script>
import { truncate, uniqBy } from 'lodash';
import ProductList from './ProductList.vue';
import EditBot from './EditBot.vue';
import popupErrorMessages from '../../../library/popup-error-messages';
import productApi from '../../../api/transactions';

export default {
  name: 'ConvoCreateTransaction',
  components: {
    ProductList,
    EditBot,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      truncate,
      showModal: false,
      showModalProduct: false,
      showModalEditBot: false,
      cart: [],
      loading: {
        submit: false,
      },
      form_bot_messages: [],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  methods: {
    onClose() {
      this.cart = [];
      this.$emit('onClose');
    },
    handleAmount(index) {
      // vue’s reactivity doesn’t work very good with updating arrays by index.
      // https://forum.vuejs.org/t/bootstraptable-vue-component-not-updating-data/78125/2
      const obj = this.cart[index];
      obj.total_amount = parseInt(obj.qty, 0) * parseInt(obj.price, 0);
      this.$set(this.cart, index, obj);
    },
    appendProduct(item) {
      item.qty = 1;
      item.total_amount = item.qty * item.price;
      this.cart.push(item);
      this.cart = uniqBy(this.cart, 'id');
      this.showModalProduct = false;
    },
    async onSubmit() {
      const is_missing = this.cart.filter((v) => v.qty < 1 || v.price < 1);
      if (this.cart.length === 0 || is_missing.length > 0) {
        return;
      }
      const form = {
        conversation_id: this.$parent.$data.selected_conversation_id,
        products: this.cart.map((v) => {
          v.qty = parseInt(v.qty, 10);
          v.price = parseInt(v.price, 10);
          return v;
        }),
        bot_messages: this.form_bot_messages,
      };
      this.loading.submit = true;
      const response = await productApi.createTransaction({
        workspace_id: this.activeWorkspace._id,
        data: form,
      });
      this.loading.submit = false;
      this.$refs.editBot.hideLoader();
      await popupErrorMessages(response);
      this.$message({
        message: this.$t('conversations.success.add_transaction'),
        type: 'success',
      });
      this.showModalEditBot = false;
      this.showModal = false;
      // this.$emit('afterSubmit', response.data.url);
    },
  },
  watch: {
    show() {
      this.showModal = this.show;
    },
  },
};
</script>
