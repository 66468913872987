var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":"Quoted Message","ok-only":"","size":"md","adaptive":true,"scrollable":true},on:{"hide":_vm.onHide},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"chat-wrapper",staticStyle:{"height":"calc(100vh - 60px - 220px)"}},[_c('div',{staticClass:"chat-content"},[_c('div',{staticClass:"chat-body"},[_c('ul',{staticClass:"messages"},[_c('li',{staticClass:"message-item",class:_vm.message.formattedType},[(_vm.message.type === 'in')?_c('div',{staticClass:"identicons",domProps:{"innerHTML":_vm._s(_vm.identicons(_vm.message.conversation_id))}}):_vm._e(),_c('div',{staticClass:"content",style:(_vm.isFromAgent(_vm.message) ? 'margin-right: 15px' : ''),attrs:{"id":'message-' + _vm.message.id}},[_c('div',{staticClass:"message",class:{ 'has-quote': _vm.message.context && _vm.message.context.body }},[_c('div',{staticClass:"bubble"},[(_vm.message.context && _vm.message.context.body)?_c('div',{staticClass:"quoted-message"},[_c('b',[_vm._v(_vm._s(_vm.message.context.type === 'out' ? _vm.$t('general.you') : _vm.message.context.contact.name))]),_c('br'),_c('message-item',{attrs:{"is_context":true,"message":_vm.message.context},on:{"handlePreview":_vm.handlePreview}})],1):_vm._e(),_c('message-item',{attrs:{"message":_vm.message},on:{"handlePreview":_vm.handlePreview}})],1),_c('span',[_c('i',{class:[_vm.message.channel_icon, _vm.message.channel_color]}),_vm._v(" "+_vm._s(_vm.message.created_at_str ? _vm.message.created_at_str : _vm.message.formattedTime)+" "),(_vm.message.type == 'out')?_c('i',{staticClass:"mdi",class:{
                    'status-read': _vm.message.status === 'read',
                    'mdi-check-all': ['sent', 'delivered', 'read'].includes(_vm.message.status),
                    'mdi-clock': _vm.message.status == 'pending',
                    'mdi-close-circle-outline': _vm.message.status === 'failed',
                    'text-danger': _vm.message.status === 'failed'
                    }}):_vm._e()]),(_vm.message.type === 'out')?_c('span',[_vm._v(_vm._s(_vm.isFromAgent(_vm.message) ? _vm.message.user.name : ''))]):_vm._e()])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }